import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.links = [
      {
        label: "Plex",
        url: "http://benefitcraft.me:32400/web/",
        note: "Stream movies and tv shows"
      },
      {
        label: "Transmission",
        url: "http://benefitcraft.me/transmission",
        note: "Download to /movies or /tv_shows"
      },
      {
        label: "unRAID",
        url: "http://benefitcraft.me:81",
        note: "unRAID Server OS"
      },
    ]
  }
  render() {
    return (
      <div className="App">
        <header className="App-header">
          <p>Personal Portal</p>
          <img src={logo} className="App-logo" alt="logo" />
          <ul className="App-link-container">
            {this.links.map(link => 
              <li className="App-list-item">
                <a
                  className="App-link"
                  href={link.url}
                  target="_blank"
                  rel="noopener noreferrer"  
                >
                  {link.label}
                </a>
                <br></br>
                <small className="App-small">{link.note}</small>
              </li>
            )}
          </ul>
        </header>
      </div>
    );
  }
}

export default App;
